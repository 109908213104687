<template>
  <div class="update-col-def-btn">
    <b-button
      variant="primary"
      size="sm"
      class="mr-1"
      @click="showModal('modal-' + id)"
    >
      Ẩn / Hiện cột
    </b-button>
    <b-button
      variant="flat-primary"
      size="sm"
      class="p-0 mr-1"
      @click="$emit('reset-filter')"
    >
      <feather-icon
        icon="XSquareIcon"
        size="24"
        class="cursor-pointer"
      />
    </b-button>
    <b-button
      v-if="showBtnFilter"
      variant="primary"
      size="sm"
      @click="$emit('trigger-filter')"
    >
      <feather-icon
        size="20"
        icon=""
      />
      Tìm kiếm
    </b-button>
    <b-modal
      :id="'modal-' + id"
      :ref="'modal-' + id"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      title="Những cột được hiển thị"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <b-card-text>
        <b-form-checkbox-group
          :id="id+ '-checkbox-group'"
          v-model="columnsShow"
          class="grid-2-columns"
        >
          <b-form-checkbox
            v-for="col in tableColumnsCheckbox"
            :key="id + '-checkbox-group-' + col.key"
            :value="col.key"
          >
            {{ col.label }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import {
  BCardText, BFormCheckbox, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BCardText,
  },
  props: {
    id: {
      type: String,
      default: () => 'table-column-toggle',
    },
    tableColumns: {
      type: Array,
      require: true,
      default: () => [],
    },
    showBtnFilter: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tableColumns: {
      immediate: true,
      handler(n) {
        if (!n) return
        this.tableColumnsCheckbox = n.map(item => ({ tdClass: '', thClass: '', ...item }))
        this.columnsShow = this.tableColumnsCheckbox.filter(item => !item.thClass.includes('d-none') && !item.tdClass.includes('d-none')).map(item => item.key)
      },
    },
  },
  mounted() {
    const saveFromLocal = JSON.parse(localStorage.getItem(this.id + this.$route.name) || '""')
    if (saveFromLocal) {
      this.columnsShow = saveFromLocal
      this.handleOk()
    }
  },
  methods: {
    handleOk() {
      this.tableColumnsCheckbox.forEach(col => {
        // nếu tìm thấy => column đó sẽ không được gán class d-none
        const found = this.columnsShow.find(key => key === col.key)
        Object.assign(col, {
          thClass: found ? col.thClass.replaceAll('d-none', '') : (`${col.thClass} d-none`),
          tdClass: found ? col.tdClass.replaceAll('d-none', '') : (`${col.tdClass} d-none`),
        })
        localStorage.setItem(this.id + this.$route.name, JSON.stringify(this.columnsShow))
      })
      this.$emit('update-columns-show', this.tableColumnsCheckbox)
    },
    handleCancel() {},
  },
  setup() {
    const columnsShow = ref([])
    const tableColumnsCheckbox = ref([])

    return {
      columnsShow,
      tableColumnsCheckbox,
    }
  },
}
</script>
