<template>
  <b-card no-body>
    <b-card-header class="pt-1 pb-1">
      <h5 class="mb-0 flex-grow-1 text-primary">
        Bộ lọc
      </h5>
      <div class="d-flex gap-1 align-items-center">
        <b-button
          v-b-tooltip.hover.bottom="'Làm mới bộ lọc'"
          variant="flat-primary"
          size="sm"
          class="p-0"
          @click="handleClearSearch"
        >
          <feather-icon
            :icon="searching ? 'SettingsIcon' : 'XSquareIcon'"
            size="24"
            class="cursor-pointer"
            :class="searching ? 'spinner' : ''"
          />
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="handleSearch"
        >
          Tìm kiếm
        </b-button>
      </div>
    </b-card-header>
    <div class="card-body">
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >
          <div class="form-group-hor mb-1">
            <label>Tỉnh</label>
            <div class="font-weight-bolder text-primary">
              Hồ Chí Minh
            </div>
          </div>

          <!--              hình thức-->
          <dynamic-form
            :id="'filter-form-hinh-thuc'"
            :value="value.formality"
            :type="'radio'"
            :label="'Hình thức'"
            :class="'form-group-hor'"
            :options="FORMALITY_CUSTOMER"
            :select-default="false"
            @update-value="val => value.formality = val"
          />

          <!--              loại-->
          <dynamic-form
            :id="'filter-form-loai'"
            :value="value.type"
            :type="'select'"
            :label="'Loại'"
            :select-default="false"
            :class="'form-group-hor'"
            :options="TYPE_PRODUCT.filter(item => item.validBy.includes(value.formality || 'thue'))"
            @update-value="val => value.type = val"
          />

          <!--            đặc biệt-->
          <dynamic-form
            :id="'filter-form-dacbiet'"
            :value="value.has_corner"
            :type="'checkbox'"
            :label="'Đặc biệt'"
            :sub-label="'Sản phẩm có góc'"
            :class="'form-group-hor'"
            @update-value="val => value.has_corner = (val ? 1 : '')"
          />

        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <!--              quận huyện-->
          <dynamic-form
            :id="'filter-form-quanhuyen'"
            :value="value.district_id"
            :type="'select'"
            :label="'Quận/Huyện'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="districts"
            :select-default="false"
            @update-value="val => value.district_id = val"
          />

          <!--              Phường/Xã-->
          <dynamic-form
            :id="'filter-form-phuongxa'"
            :value="value.ward_id"
            :type="'select'"
            :label="'Phường/Xã'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="wards"
            :select-default="false"
            @update-value="val => value.ward_id = val"
          />

          <!--              Đường-->
          <dynamic-form
            :id="'filter-form-duong'"
            :value="value.street_id"
            :type="'select'"
            :label="'Đường'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="streets"
            :select-default="false"
            @update-value="val => value.street_id = val"
          />

        </b-col>

        <b-col
          cols="12"
          lg="4"
        >
          <!--          <div-->
          <!--            class="form-group-hor mb-1"-->
          <!--          >-->
          <!--            <label>Số lầu</label>-->
          <!--            <div class="grid-2-columns">-->
          <!--              <b-input-group-->
          <!--                append="≤"-->
          <!--                class="input-group-merge"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  v-model="value.floor_number"-->
          <!--                  placeholder="Tối thiểu"-->
          <!--                />-->
          <!--              </b-input-group>-->
          <!--              <b-input-group-->
          <!--                append="≤"-->
          <!--                class="input-group-merge"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  v-model="value.max_floor_number"-->
          <!--                  placeholder="Tối đa"-->
          <!--                />-->
          <!--              </b-input-group>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="form-group-hor mb-1">
            <label>Ngang x Dài</label>
            <div class="grid-2-columns">
              <b-input-group
                append="≤"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="value.width"
                  placeholder="Tối thiểu"
                />
              </b-input-group>
              <b-input-group
                append="≤"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="value.length"
                  placeholder="Tối thiểu"
                />
              </b-input-group>
            </div>
          </div>

          <!--            Diện tích-->
          <dynamic-form
            :id="'filter-form-acreage'"
            :value="value.acreage"
            :type="'number'"
            placeholder="Tối thiểu"
            :label="'Diện tích'"
            :class="'form-group-hor'"
            append-text="≤"
            @update-value="val => value.acreage = val"
          />

        </b-col>
      </b-row>
      <hr v-if="usernameByFilter">
      <b-row v-if="usernameByFilter">
        <b-col
          cols="12"
          md="4"
        >
          Nhân viên tìm kiếm: <span class="text-primary font-weight-bolder">{{ usernameByFilter }}</span>
        </b-col>
        <b-col
          cols="12"
          md="8"
          class="d-flex flex-wrap"
        >
          <span class="mr-1">Thời gian tìm kiếm:</span>
          <div class="d-flex mr-1">
            <span class="mr-1">Tháng</span>
            <v-select
              v-model="monthFilter"
              class="select-size-sm d-inline-block mr-25"
              label="name"
              :options="MONTH_OPTIONS"
            />
          </div>
          <div class="d-flex">
            <span class="mr-1">Năm</span>
            <v-select
              v-model="yearFilter"
              class="select-size-sm d-inline-block"
              label="name"
              :options="YEAR_OPTIONS"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BFormInput, BInputGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import vSelect from 'vue-select'
import useCustomerList from '@/views/customer/list/useCustomerList'
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import http from '@/global/http'
import store from '@/store'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
    BCardHeader,
    BFormInput,
    BInputGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    month: {
      type: [String, Number],
      default: null,
    },
    year: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  computed: {
    currencyList() {
      return this.CURRENCY.filter(item => item.validBy.includes('vnd')) || []
    },
  },
  watch: {
    month: {
      immediate: true,
      handler(n) {
        this.monthFilter = n
      },
    },
    year: {
      immediate: true,
      handler(n) {
        this.yearFilter = n
      },
    },
  },
  created() {
    const { filter } = this.$route.query
    this.usernameByFilter = JSON.parse(filter || '""').username || ''
    this.$call(store.dispatch('app/fetchCareers'))
  },
  methods: {
    updateFilterPrice() {
      this.value.currency = this.price.currency
      this.value.input_price = this.getPrice(this.price.from, this.value.currency)
    },
    getPrice(val, currency) {
      let price
      switch (currency) {
        case 'million':
          price = val * 1000000
          break
        case 'billion':
          price = val * 1000000000
          break
        default:
          price = val
          break
      }
      return price || ''
    },
  },
  setup(props, { emit }) {
    const searching = ref(null)

    const price = reactive({
      from: ref(''),
      to: ref(''),
      currency: ref('million'),
    })

    const monthFilter = ref(null)
    const yearFilter = ref(null)
    const usernameByFilter = ref(null)

    const careers = computed(() => store.state.app.careers)

    const {
      wards, streets, districts, users, customerListMenuType,
    } = useCustomerList()

    const handleSearch = () => {
      emit('update:loading', true)
      if (searching.value) {
        clearTimeout(searching.value)
        searching.value = null
        emit('update:loading', false)
      }
      // new search delay
      searching.value = setTimeout(() => {
        emit('search')
        // waiting for vue update -> parent -> child(here)
        setTimeout(() => {
          clearTimeout(searching.value)
          searching.value = null
          emit('update:loading', false)
        }, 50)
      }, 500)
    }

    const getDateRange = () => {
      if (!yearFilter.value) {
        return {
          from: '',
          to: '',
        }
      }

      return {
        from: !monthFilter.value ? new Date(yearFilter.value, 0, 1)
          : new Date(yearFilter.value, parseFloat(monthFilter.value - 1), 1),
        to: !monthFilter.value ? new Date(yearFilter.value, 12, 0) : new Date(yearFilter.value, monthFilter.value, 0, 23, 59, 59),
      }
    }

    const handleClearSearch = () => {
      searching.value = null
      monthFilter.value = null
      yearFilter.value = null
      usernameByFilter.value = null
      emit('clear-filter', { province_id: 1 })
      emit('update:loading', false)
    }

    watch(props.value, () => {
      handleSearch()
    })

    watch(customerListMenuType, () => {
      handleClearSearch()
    })

    watch([monthFilter, yearFilter], () => {
      const {
        from,
        to,
      } = getDateRange()
      // eslint-disable-next-line no-param-reassign
      props.value.from = http.getISODate(from)
      // eslint-disable-next-line no-param-reassign
      props.value.to = http.getISODate(to)
    })

    return {
      price,
      searching,
      monthFilter,
      yearFilter,
      usernameByFilter,
      districts,
      customerListMenuType,
      users,
      wards,
      streets,
      careers,
      handleSearch,
      handleClearSearch,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
