<template>
  <div>
    <ListCustomerUI
      ref="ListCustomerUI1"
      :config="config"
      @refresh-list="handleFetchNewData"
    />

    <hr>

    <ListCustomerUI
      ref="ListCustomerUI2"
      :config="{}"
      :key-refresh-list="keyRefreshList"
    />
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import customerStoreModule from '@/views/customer/customerStoreModule'
import useCustomerList from '@/views/customer/list/useCustomerList'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import axiosIns from '@/libs/axios'
import ListCustomerUI from '@/views/customer/list/ListCustomerUI.vue'

export default {
  components: {
    ListCustomerUI,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      keyRefreshList: 1,
      loading: false,
      ability,
      subject,
      config: {
        viewMode: 'appointment',
        tableDataName: 'Lịch hẹn gặp khách',
      },
    }
  },
  computed: {
    currencyList() {
      return this.CURRENCY.filter(item => item.validBy.includes('vnd')) || []
    },
    actionsButton() {
      const validActions = []
      if (ability()
        .can('export-excel')) {
        validActions.push(this.ACTIONS_BUTTON.EXPORT_EXCEL)
      }
      return validActions
    },
  },
  created() {
    const { filter } = this.$route.query
    Object.assign(this.filter, JSON.parse(filter || '""'))
    this.monthFilter = this.filter.monthFilter || ''
    this.yearFilter = this.filter.yearFilter || ''
    store.dispatch('app/fetchUsers')
    this.$call(store.dispatch('app/fetchCareers'))
    this.filter.province_id = '1' // HCM
    this.fetchCustomers()
      .finally(() => {
        this.loading = false
      })
    // waiting for watching done
    setTimeout(() => {
      this.created = true
    }, 500)
  },
  methods: {
    handleFetchNewData() {
      this.keyRefreshList += 1
    },
    handleFastUpdateCustomer() {
      this.$call(this.updateCustomer(this.currentCustomerUpdate), true)
        .then(({ id }) => {
          const found = this.customers.find(c => c.id === id)
          if (found) {
            Object.assign(found, this.currentCustomerUpdate)
          }
        })
    },
    fetchCustomerHistory(row) {
      this.currentCustomerClicked = row
      axiosIns.get(`/api.client/history/${row.id}`)
        .then(res => {
          this.currentCustomerHistory = res.data && res.data.data
        })
        .catch(e => {
          this.$toastr(`Có lỗi xảy ra!${e}`, 'danger')
        })
    },
    rowClicked(row) {
      this.gotoCustomerDetail(row)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    setTableHeaderFilter(filterName, filterValue) {
      this.tableHeaderFilter[filterName] = filterValue
    },
    gotoCustomerEdit(customer) {
      this.$router.push({
        name: `customer-${this.customerMenuType}-edit`,
        params: { id: customer.id },
      })
    },
    handleActionsButton(btn) {
      if (btn.value === 'excel') {
        this.$refs['modal-export-excel'].show()
      }
    },
    handleExportExcel() {
      this.$toastr('Đang xuất file, vui lòng chờ giây lát!')
      this.$call(this.exportExcelCustomer()
        .then(res => {
          this.downloadUrl(res.data, 'excel')
        }))
    },
    openModalFastEdit(customer) {
      Object.assign(this.currentCustomerUpdate, customer)
      this.$refs['modal-fast-edit-customer'].show()
    },
    openModalConfirmDeleteCustomer(customer) {
      this.currentCustomer = customer
      this.$refs['modal-confirm-delete-customer'].show()
    },
    handleDeleteCustomer() {
      this.$call(this.deleteCustomer(this.currentCustomer.id), true)
        .then(() => {
          const foundIdx = this.customers.findIndex(item => item.id === this.currentCustomer.id)
          if (foundIdx !== -1) {
            this.customers.splice(foundIdx, 1)
          }
        })
    },
    gotoCustomerDetail({ id }) {
      const route = this.$router.resolve({
        name: `customer-${this.customerMenuType}-detail`,
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchCustomers()
        .finally(() => {
          this.loading = false
        })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    resetHeaderFilter(initFilter) {
      this.clearHeaderFilter(initFilter)
    },
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = 'customer'
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    return {
      ...useCustomerList(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.customer-actions-wrapper {
  display: flex;
  gap: 1rem;

  > * {
    padding: .25rem !important;
  }

  .customer-badge {
    position: absolute;
    right: 0;
    font-size: .7rem !important;

    &.hot {
      top: .25rem;
    }

    &.corner {
      bottom: .25rem;
    }
  }
}
</style>
