import { ref, reactive } from '@vue/composition-api'

const customerFilterModal = () => reactive({
  page: ref(null),
  perpage: ref(null),
  q: ref(null),
  from: ref(null),
  to: ref(null),
  type_product: ref(null),
  type: ref(null),
  id: ref(null),
  user_id: ref(null),
  formality: ref(null),
  has_corner: ref(null),
  currency: ref(null),
  province_id: ref(null),
  district_id: ref(null),
  ward_id: ref(null),
  street_id: ref(null),
  house_number: ref(null),
  room_number: ref(null),
  phone: ref(null),
  width: ref(null),
  length: ref(null),
  acreage: ref(null),
  price: ref(null),
  company_name: ref(null),
  customer_type: ref(null),
  status: ref(null),
  career: ref(null),
  product_type: ref(null),
})

export default customerFilterModal
